import React from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
	const { t } = useTranslation();
	// services details
	const services = t('project.project-list', { returnObjects: true });

	return (
		<section id='services' className='section bg-light'>
			<div className='container'>
				{/* Heading */}
				<p className=' text-center mb-2 wow fadeInUp'>
					<span className='bg-primary text-dark px-2'>
						{t('project.section-title')}
					</span>
				</p>
				<h2 className='text-10 fw-600 text-center mb-5 wow fadeInUp'>
					{t('project.section-subtitle')}
				</h2>
				<p className='text-5'>{t('project.text-content')}</p>
				{/* Heading end*/}
				<div className='row gy-5 mt-5'>
					{services.length > 0 &&
						services.map((service, index) => (
							<div className='col-sm-6 col-lg-6 wow fadeInUp' key={index}>
								<div className='featured-box text-center px-md-4'>
									<div className='featured-box-icon text-primary text-13'>
										{' '}
										<i className={service.icon} />
									</div>
									<h3 className='text-6 fw-600 mb-3'>{service.name}</h3>
									<p className='text-muted mb-0'>{service.desc} </p>
								</div>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default Services;
